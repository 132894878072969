"use client";
import { useEffect } from "react";
import "../../../public/assets/css/main.css";
import { usePathname } from "next/navigation";
import { ToastContainer } from "react-toastify";
import useMounted from "@/hooks/use-mounted";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function RootLayout({ children }) {
  const path = usePathname();
  const mounted = useMounted();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      AOS.init({
        duration: 1000,
        once: true,
        offset: 0,
      });
    }
  }, [path]);

  if(!mounted) {
    return null;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
    </>
  );
}
